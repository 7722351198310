.carouselSection {
  position: relative;
  z-index: var(--z-index-2x);
  width: auto;
  height: 177px;
  overflow: hidden;
}

.photoContainer {
  overflow: hidden;
  border-bottom: none;
  border-radius: var(--listing-card-preview-border-radius)
    var(--listing-card-preview-border-radius) 0 0;
  min-width: 100%;
}
