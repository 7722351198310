/*
@contract listing-card-preview {
  card-background-color {
    dark: BackgroundColor
    hover-dark: BackgroundColor
    light: BackgroundColor
    hover-light: BackgroundColor
  }
  info-card-color {
    dark: Color
    light: Color
    hover: {
      dark: Color
    }
  }
  info-card-details-text-color {
    dark: Color
    light: Color
  }
  border-radius: BorderRadius
  info-section-height: Height
  image-aspect-ratio: AspectRatio
}
*/

.link {
  text-decoration: none;
  display: block;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.infoCardLight,
.infoCardDark {
  border-bottom-right-radius: var(--listing-card-preview-border-radius);
  border-bottom-left-radius: var(--listing-card-preview-border-radius);
  height: var(--listing-card-info-section-height);
}

.infoCardLight {
  background: white;
  color: var(--listing-card-preview-info-card-color-light);
}

.infoCardDark {
  color: var(--listing-card-preview-info-card-color-dark);
}

.todaysPick .infoCardLight,
.todaysPick .infoCardDark {
  border: 0;
  border-bottom-right-radius: calc(
    var(--listing-card-preview-border-radius) / 2
  );
  border-bottom-left-radius: calc(
    var(--listing-card-preview-border-radius) / 2
  );
}

.cardLight,
.cardDark {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--listing-card-wrapper-height);
  position: relative;
  border-radius: var(--listing-card-preview-border-radius);
  box-shadow: var(--listing-card-preview-info-card-shadow);
}

.cardLight {
  background-color: var(--listing-card-preview-card-background-color-light);
}

.cardLight .infoCardLight {
  border-radius: 0 0 var(--listing-card-preview-border-radius)
    var(--listing-card-preview-border-radius);
  border: var(--listing-card-preview-border);
  border-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--listing-card-info-section-height);
}

.cardDark .infoCardDark {
  background-color: var(--listing-card-preview-card-background-color-dark);
}

.cardDark:hover .infoCardDark {
  background-color: var(
    --listing-card-preview-card-background-color-hover-dark
  );
}

.cardTextSmall {
  padding: var(--space-3x);
  font-size: var(--font-size-body);
}

.cardTextMedium {
  padding: var(--space-3x);
  font-size: var(--font-size-body);

  @media (--screen-md) {
    font-size: var(--font-size-md);
    padding: var(--space-4x);
  }

  @media (--screen-xl) {
    padding: 18px;
  }

  @media (--screen-2xl) {
    font-size: 18px;
  }
}

.infoCardFirstLine {
  display: flex;
  justify-content: space-between;
}

.ratingWrapper {
  --rating-star-size: var(--font-size-sm);
  --rating-size: var(--font-size-sm);
}

.name,
.price {
  font-weight: var(--font-weight-semibold);
}

.name,
.price,
.detailTextDark,
.detailTextLight {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detailTextLight {
  color: var(--listing-card-preview-info-card-details-text-color-light);
}

.detailTextDark {
  color: var(--listing-card-preview-info-card-details-text-color-dark);
}

.cardDark:hover .detailTextDark,
.cardDark:hover .infoCardDark {
  color: var(--listing-card-preview-info-card-color-hover-dark);
}

.photoWrapper {
  picture {
    display: contents;
  }
}

.photo {
  border: var(--listing-card-preview-border);
  border-bottom: none;
  border-radius: var(--listing-card-preview-border-radius)
    var(--listing-card-preview-border-radius) 0 0;
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: var(--listing-card-preview-image-aspect-ratio);
}

.todaysPick {
  overflow: initial;
  position: relative;
  box-shadow: var(--listing-card-preview-info-card-shadow);
  border-top-left-radius: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% - 4px);
    box-shadow: 0 0 0 4px var(--listing-card-preview-primary-color);
    pointer-events: none;
  }

  &::after {
    border-radius: 0 8px 8px;
  }

  &::before {
    border-radius: 8px;
  }
}

.todaysPickText {
  gap: var(--space-1x);
  background: var(--listing-card-preview-primary-color);
  color: white;
  position: absolute;
  height: 28px;
  top: 0;
  left: 0;
  transform: translate(
    calc(calc(var(--listing-card-preview-border-radius) / 2) * -1),
    -100%
  );
  font-size: 12px;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  padding: var(--space-2x);
  border-radius: calc(var(--listing-card-preview-border-radius) / 2)
    calc(var(--listing-card-preview-border-radius) / 2) 0 0;
}

.todaysPick .cardTextSmall {
  padding: var(--space-3x) calc(var(--space-3x) - 4px) var(--space-3x);
}

.todaysPick .cardTextMedium {
  padding: var(--space-3x) calc(var(--space-3x) - 4px) var(--space-3x);

  @media (--screen-md) {
    padding: var(--space-4x) calc(var(--space-4x) - 4px) var(--space-4x);
  }

  @media (--screen-xl) {
    padding: 18px 14px 14px;
  }
}

.ctaButtons {
  padding-top: var(--space-3x);
  --icon-button-width: 100%;
  --button-font-size: var(--font-size-body);
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactedOn {
  background: var(--color-gray-100);
  padding: var(--space-0-5x) var(--space-1x);
  border-radius: var(--space-1x);
  position: absolute;
  color: var(--color-gray-600);
  font-weight: var(--font-weight-semibold);
  top: var(--space-2x);
  font-size: var(--font-size-sm);
  left: var(--space-3x);
}
