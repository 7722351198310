.slide {
  position: relative;
  object-fit: cover;
}

.btnPrev,
.btnNext {
  width: 70px;
  height: 70px;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-top: -40px;
  touch-action: manipulation;
  position: absolute;
  top: 50%;
  z-index: var(--z-index-2x);
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
  color: var(--color-white);
  font-size: var(--font-size-lg);
}

.btnPrev::before,
.btnNext::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--color-gray-500);
  opacity: 0.6;
}

.btnPrev svg,
.btnNext svg {
  position: relative;
  z-index: var(--z-index-1x);
}

.btnPrev svg {
  left: var(--space-3x);
}

.btnNext svg {
  right: var(--space-3x);
}

.btnPrev {
  left: 0;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-left: -35px;
  padding-top: var(--space-2x);
}

.btnNext {
  right: 0;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-right: -35px;
  padding-top: var(--space-2x);
}

.btnNext:hover::before,
.btnPrev:hover::before {
  background-color: var(--color-gray-600);
  opacity: 0.8;
}

.carousel {
  position: relative;
  overflow: hidden;
  background-color: var(--color-gray-200);
  height: 240px;
  min-width: 100%;
}

.image {
  position: relative;
  object-fit: cover;
  display: var(--listing-card-carousel-display);
  height: var(--listing-card-carousel-height);
  width: var(--listing-card-carousel-width);
}
